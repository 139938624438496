import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as uri from '@app/properties.json';

@Injectable()
export class ScoringService {
    constructor(private _http: HttpClient) { }
    private headers = new HttpHeaders(); 
    savePhone(body) {
        this.headers = this.headers.set('Content-Type', 'application/json; charset=utf-8');
        return this._http.post(uri['uri_scoring']+'/phone/register/v2', body, {headers: this.headers, responseType: 'json'}, );
    }
}